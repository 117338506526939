import { Injectable } from '@angular/core';
import {BehaviorSubject, catchError, of, tap} from "rxjs";
import {FirewallRules} from "../model/FirewallRule";
import {FirewallRulesRepo} from "../repo/firewall-rules.repo";

@Injectable({
  providedIn: 'root'
})
export class FirewallRulesStore {
  private rulesSubject = new BehaviorSubject<FirewallRules[]>([]);
  public rules$ = this.rulesSubject.asObservable();

  constructor(private repo : FirewallRulesRepo) { }

  getRules(entity: string){
    this.repo.getFirewallRules(entity).pipe(
      tap((data) => {
        this.rulesSubject.next(data);
      }),
      catchError((error) => {
        console.error('Error fetching NetworkObjects:', error);
        return of([]);
      })
    ).subscribe();
  }
}
